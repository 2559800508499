<template>
  <div class="examination_questions">
      <div>
          <!-- <van-button type="primary" @click="setGoTo">主要按钮</van-button> -->
           <van-nav-bar
            title="考前押题"
            left-arrow
            @click-left="onClickLeft"
            />
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
      >
        <div  v-if="list&&list.length>0">
          <div class="chapter_card" v-for="item in list">
            <div class="chapter_box detial">
              <div class="chapter_box_title">
                <span>{{item.testPaperName}}</span>
              </div>
              <div class="chapter_cirle-box">
                <div  class="question_cirle">
                  <div class="question_cirle-continer">
                      <div class="question_cirle-yes" :style="setCirleNum(item)"></div>
                  </div>   
                  <div class="question_num">{{item.completeNumber}}/{{item.countNumber}}</div>
                </div>
                <div  >
<!--                  <img class="chapter_cirle-logo"  src="@/assets/all_Icon/drawable-xhdpi/icon_xiugai.png" />-->
                  <div class="btns btn report" v-show="item.isJiaojuan === 1" @click="btnClick('report',item)">答题报告</div>
                  <div class="btns btn again" v-show="item.isJiaojuan === 1" @click="btnClick('again',item)">重新答题</div>
                  <div class="btns btn" v-show="item.isJiaojuan === 0" @click="btnClick('continueQuestions',item)">继续答题</div>
                </div>
              </div>
              
            </div>
             
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无数据"></van-empty>
        </div>
      </van-list>

      
  </div>
</template>

<script>
import { getChapterList} from "@/api/chapter.js";

export default {
  data() {
      return {
        list: [],
        loading: false,
        finished: true,
       
      }
    },
  name: 'examination_questions',
  created(){
    
    
    this.init();
    this.$store.commit('setQuestionSwipeIndex',{index:-1});

  },
  methods: {
    init(){
      const ids = this.$route.query.id;
      getChapterList(3,ids).then((res)=>{
        if(res.data.code == 0){
          this.list = res.data.data;
        }else{
          this.list = [];
        }
      });

    },
    onClickLeft() {
     this.$router.go(-1);
    },
    setCirleNum(item){
      let num = item.completeNumber/item.countNumber*100 + "%";
      return {
        "width":num
      }
    },
    btnClick(type,item) {
      if(type === 'report') {
        this.$store.commit('setResolution_method',{key:'answer_res'});

        this.$router.push({
          path:"/answerReport",
          query:{
            id:item.testPaperId,
            path:"/chapter",
            source: 'list',
            type: 'testPaper'
          }
        })
      }else if(type === 'again') {
        this.$store.commit('setResolution_method',{key:'header_res'});
        this.$router.push({
          path:"/examination_questions_detail",
          query:{
            id:item.testPaperId,
            type: 'test',
            typeBtn: 'rest'
          }
        })
      }else if(type === 'continueQuestions') {
        this.$store.commit('setResolution_method',{key:'header_res'});
        this.$router.push({
          path:"/examination_questions_detail",
          query:{
            id:item.testPaperId,
            type: 'test',
            typeBtn: 'continue'
          }
        })
      }
    },
    goTo(item) {
      this.$store.commit('setResolution_method',{key:'header_res'});
      this.$router.push({
        path:"/examination_questions_detail",
        query:{
          id:item.testPaperId,
          type: 'test'
        }
      })
    }
  },
  
  mounted () {
         
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>

.examination_questions{
    width: 100%;
    height: 100%;
    background: #F8F8F8;
    
}
.chapter_card{
  display: flex;
  flex-direction: column;
  margin: 17px 14px;
  background: white;
  height: 82px;

}
.chapter_box{
  
  padding: 19px 8px;
}
.chapter_box_title{
  width:calc(100% - 130px);
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.chapter_cirle-box{
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.chapter_cirle-logo{
  width:16px;
  height: 15px;
}

.question_cirle{
  width:calc(100% - 120px);
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .question_cirle-continer{
    width:calc(100% - 63px);
    height: 10px;
    background: #E7ECFF;
    border-radius: 5px;
  }
  .question_cirle-yes{
    width:50%;
    height: 10px;
    background: #5F7DFF;
    border-radius: 5px;

  }
}
.question_num{
  width: 43px;
  height: 15px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 15px;
}

.detial {
  margin: 0 10px 0 10px;
  position: relative;
  .btn {
    height: 28px;
    line-height: 28px;
    background: #5D7DFF;
    border-radius: 5px;
    color: #fff;
    padding: 0 7px;
  }
  .btns {
    position: absolute;
    right: 0;
    top: 38px;
    margin-top: -19px;
  }

  .again {
    position: absolute;
    right: 0;
    top: 38px;
    margin-top: -19px;
    background: #EA6A76;
  }

  .report {
    position: absolute;
    right: 70px;
    top: 38px;
    margin-top: -19px;
  }
}
</style>
