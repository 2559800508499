
import request from '@/axios/index';



//历年/模拟/考前试卷列表 classify 1.模拟试卷 2.历年真题 3.考前押题 
export function getChapterList(classify,secondId) {
    return request({
        url: '/api/app/v1/test_paper/paper/list?classify='+classify+"&secondId="+secondId,
        method: 'post',
    })
}

//历年/模拟/考前试卷获取对应题 classify 1.模拟试卷 2.历年真题 3.考前押题 
export function getChapterDetailList(testPaperId,type) {
    return request({  
        url: '/api/app/v1/test_paper/paper/subjectList?testPaperId='+testPaperId+"&type="+type,
        method: 'post',
    })
}

//  添加删除标记
export function add_sign(subjectId,type,testPaperId) {
    return request({
        url: '/api/app/v1/test_paper/add_sign?testPaperId='+testPaperId+"&type="+type+"&subjectId="+subjectId,
        method: 'post',
    })
}

// 添加收藏
export function add_collect(subjectId) {
    return request({
        url: 'api/app/v1/subject_collect/add_collect?subjectId='+subjectId,
        method: 'post',
    })
}



//  删除收藏
export function deleted_collect(subjectIds) {
    return request({
        url: '/api/app/v1/subject_collect/deleted_collect?subjectIds='+subjectIds,
        method: 'post',
    })
}

//  保存答案 -- 章节练习  
export function save_subject(answerTimeLength,nodeType,subjectId,answerId,answerText) {
    return request({
        url: '/api/app/v1/test_paper/exercise/save_subject?answerTimeLength='+answerTimeLength+'&nodeType='+nodeType+'&subjectId='+subjectId+'&answerId='+answerId+'&answerText='+answerText,
        method: 'post',
    })
}
//  保存答案 -- 历年真题/试卷/考前押题
export function pape_save_subject(answerTimeLength,testPaperId,subjectId,answerId,answerText,score) {
    return request({
        url: 'api/app/v1/test_paper/paper/save_subject?answerTimeLength='+answerTimeLength+'&testPaperId='+testPaperId+'&subjectId='+subjectId+'&answerId='+answerId+'&answerText='+answerText+'&score='+score,
        method: 'post',
    })
}
//章节练习--交卷
export function assignment_paper(nodeId,nodeType) {
    return request({
        url: 'api/app/v1/test_paper/exercise/assignment_paper?nodeId='+nodeId+'&nodeType='+nodeType,
        method: 'post',
    })
}
//历年真题/模拟试卷/考前押题--交卷
export function test_paper_paper(testPaperId) {
    return request({
        url: 'api/app/v1/test_paper/paper/assignment_paper?testPaperId='+testPaperId,
        method: 'post',
    })
}